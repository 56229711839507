<template>
  <div>
    <lms-card>
      <lms-card-item clickable @click="goTo('profile-data')">
        {{ $t('profile.options.personalData') }}

        <template #actions>
          <v-icon>mdi-menu-right</v-icon>
        </template>
      </lms-card-item>

      <lms-card-item clickable @click="goTo('profile-language')">
        {{ $t('profile.options.language') }}

        <template #actions>
          <v-icon>mdi-pencil</v-icon>
        </template>
      </lms-card-item>

      <lms-card-item clickable @click="resetPassword">
        {{ $t('profile.options.resetPassword') }}
      </lms-card-item>

      <lms-card-item clickable @click="logout">
        {{ $t('profile.options.logout') }}
      </lms-card-item>
    </lms-card>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    goTo(name) {
      this.$router.push({ name });
    },
    async resetPassword() {
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.profile.resetPassword.title'),
        content: this.$t('alerts.profile.resetPassword.content'),
      });
      if (response) {
        await this.$store.dispatch('auth/getData', ['email']);
        await this.$store.dispatch('user/recoveryPassword', this.user.email);
        this.$store.commit('snackbar/show', {
          content: this.$t('loginPage.pages.forgot.success'),
          type: 'success',
        });
      }
    },
    async logout() {
      const result = await this.$store.dispatch('auth/logout');
      this.$router.push({
        name: result.redirect,
      });
      window.location.reload();
    },
  },
};
</script>
