<template>
  <div>
    <f-page-header :title="title" :prevLinks="[]" />

    <lms-default-loader v-if="isLoading" />

    <f-container topBottom v-if="!isLoading">
      <default-panel />
    </f-container>
  </div>
</template>

<script>
import DefaultPanel from '../../components/Profile/ViewPanel.vue';

export default {
  components: {
    DefaultPanel,
  },
  computed: {
    title() {
      return this.$t('labels.profile');
    },
  },
  created() {
    this.$store.commit('system/loading', false);
  },
};
</script>
